<template>
  <div class="addCourseBg">
    <div class="addCourseWhite" v-if="addCourseOne">
      <div>
        <div class="addCourseKeFlex">
          <span>课程名称</span>
          <el-input v-model="courserData.name" placeholder="请输入课程名称"></el-input>
        </div>
        <div class="addCourseKeFlex">
          <span>英文名称</span>
          <el-input v-model="courserData.englishName" placeholder="请输入课程名称"></el-input>
        </div>
        <div class="addCourseKeFlex">
          <span>课程编码</span>
          <el-input v-model="courserData.courseCode" placeholder="请输入课程名称"></el-input>
        </div>
        <div class="addCourseKeFlex">
          <span>课程封面</span>
          <custom-upload v-model="courserData.logo"  bizType='examinationType' aliVideoOssData="1"></custom-upload>
        </div>
        <div class="addCourseKeFlex">
          <span>课程简介</span>
          <el-input
            type="textarea"
            :autosize="{ minRows: 6, maxRows: 16 }"
            placeholder="请输入内容"
            v-model="courserData.profiles"
          >
          </el-input>
        </div>
        <p class="nextStep">
          <el-button type="primary" plain @click="goNextStepone" >下一步</el-button>
        </p>
      </div>
    </div>
<!--    第二步-->
    <div v-if="addCourseTwo">
      <div class="courseTwowidth">
        <div class="courseTwoPadding">
          <div class="addCourseKeFlex">
            <span>课程类别</span>
            <el-select v-model="courserData.courseMold" placeholder="请选择">
              <el-option
                  v-for="item in courserClassFy"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="addCourseKeFlex">
            <span>学时安排</span>
            <div>
              <div class="courseClassFlex">
                <el-input v-model="courserData.totalClassHour"></el-input>
                <span>总学时</span>
              </div>
            </div>
          </div>
          <div class="addCourseKeFlex">
            <span>授课对象</span>
            <el-select v-model="courserData.orientCrowd" placeholder="请选择">
              <el-option
                  v-for="item in courserObjArr"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
<!--            <p>下拉选择项（中职、高职、本科）</p>-->
          </div>
          <div class="addCourseKeFlex">
            <span>适用专业</span>
            <el-input v-model="courserData.applicableToMajor"></el-input>
          </div>
          <div class="addCourseKeFlex">
            <span>教学大纲</span>
            <el-upload
                class="upload-demo"
                :limit="1"
                :http-request="uploadFile"
                action="/frontApi/baseFile/upload"
            >
              <el-button icon="el-icon-upload2" type="primary">点击上传</el-button>
              <span style="font-size: 12px">(上传数量为1)</span>
            </el-upload>
            <div v-if="progress">
              <el-progress :percentage="percentage" ></el-progress>
            </div>
          </div>
          <div  v-for="(item,index) in teacherProgramme" :key="index">
            <div class="addCourseKeFlex" v-if="item.resourceName&&item.resourceName!==''">
              <p class="programmeP" >{{item.resourceName}}</p>
              <el-button type="danger" icon="el-icon-delete" circle @click="deleteResourceListIndex(item)"></el-button>
            </div>
          </div>
          <div class="addCourseKeFlex">
            <span>备注</span>
            <el-input v-model="courserData.remarks"></el-input>
          </div>
          <p class="nextStep">
            <el-button type="primary" plain @click="goOneStep">上一步</el-button>
            <el-button type="primary" plain @click="goNextStep" >下一步</el-button>
          </p>
        </div>
      </div>
    </div>
<!--    第三步-->
    <div class="constructionChapter" v-if="addCoursethree">
      <div class="constructionList">
        <h2>新建章节</h2>
        <div v-for="(item,index) in courseChapterArr" :key="index">
          <div class="constructionChapters">
            <h4>{{item.name}}</h4>
            <div>
              <el-button type="primary" plain size="small" icon="el-icon-delete" @click="deleteCoursePreper(item)"></el-button>
              <el-button type="primary" plain size="small"  icon="el-icon-edit" @click="amendCoursechapter(item)"></el-button>
            </div>
          </div>
          <div v-for="(itemChildren, indexChildren) in item.children"
               :key="indexChildren" class="courseZjChildren">
            <span class="festival">{{itemChildren.name}}</span>
            <div>
              <el-button type="primary" plain size="small" icon="el-icon-delete" @click="deleteCoursePreper(itemChildren)"></el-button>
              <el-button type="primary" plain size="small"  icon="el-icon-edit" @click="amendCourseJIe(itemChildren,item)"></el-button>
            </div>
          </div>
          <div class="addJIeFlex">
            <p class="addJie" @click="ChnageSection(item)">+新增小节</p>
          </div>
        </div>
        <div class="addJIeFlex">
          <h4 class="addzhang" @click="ChnageChapterSection">+新增一章</h4>
        </div>

        <p class="addzJTwo">
          <el-button type="primary" plain  @click="lastSuccecs">完成</el-button>
        </p>
      </div>
    </div>
    <el-dialog :visible.sync="constructionSection" :show-close="true">
      <div class="addCourseWindow">
        <div class="addCourseKeFlex">
          <span>本章标题</span>
          <el-input disabled v-model="addCourseJie.name"></el-input>
        </div>
        <div >
          <div class="addCourseKeFlex" v-if="chapterJieShow">
            <span>本章小节</span>
            <el-input v-model="coursejieData"></el-input>
          </div>
        </div>
        <div class="addFinish" @click="createCourseCj()">
          <p>添加完成</p>
        </div>
      </div>
    </el-dialog>
<!--    修改节弹窗-->
    <el-dialog :visible.sync="amendJieWindow" :show-close="true">
      <div class="addCourseWindow">
        <div >
          <div v-if="amendJIeSHow" class="addCourseKeFlex">
            <span>本章小节</span>
            <el-input v-model="addCourseJie.name"></el-input>
          </div>
        </div>
        <div class="addFinish" @click="createCourseCj()">
          <p>添加完成</p>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="courseChapterwindow" :show-close="true">
      <div class="addCourseWindow">
        <div class="addCourseKeFlex">
          <span>本章标题</span>
          <el-input v-if="chapterWindowSHow &&!amendChapterActive" v-model="courseChapter.name"></el-input>
          <el-input v-if="addCourseJie.name&&amendChapterActive" v-model="addCourseJie.name"></el-input>
        </div>
        <div class="addFinish" @click="createCourseCj()">
          <p>添加完成</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import CustomUpload from "@/components/custom-upload";
import {filePlayType} from "@/utils/fileUtil";
import {createUploader} from "@/utils/aliyunVideoUpload";
import {
  addTeacherResourceAliYun,
  teacherUpLoad,
  createAmendTeacherCourse,
  cresteTeacherCoursePreper,
  deleteTeacherCoursePreper,
  addTeacherResource,
  selectAddTeacherResourceList, deleteAddTeacherResourceList
} from "@/api";
export default {
  components: {
    CustomUpload,
  },
  data() {
    return {
      procedureActive: 0,
      titleInput: "",
      introductionINput: "",
      twoIntroductionINput: "",
      addCourseOne: true,
      addCourseTwo:false,
      addCoursethree:false,
      operationShow: false,
      constructionSection: false,
      threeAddCourse: false,
      resourceShow:false,
      resourceUpLoadShow:false,
      fourAddCourse:false,
      targetArr:[
        {id:1,name:'教学目标',subhead:'新建一个课程',img:'images/target.png'},
        {id:1,name:'教学设计',subhead:'新建一个课程',img:'images/design.png'},
        {id:1,name:'电子教材',subhead:'新建一个课程',img:'images/textbook.png'},
        {id:1,name:'拓展资料',subhead:'新建一个课程',img:'images/expand.png'},
        {id:1,name:'课程微课',subhead:'新建一个课程',img:'images/microlecture.png'},
        {id:1,name:'动画',subhead:'新建一个课程',img:'images/animation.png'},
        {id:1,name:'教学视频',subhead:'新建一个课程',img:'images/video.png'},
        {id:1,name:'习题作业',subhead:'新建一个课程',img:'images/performance.png'},
      ],

      courserClassFy:[
        {
          value: '理论教学',
          label: '理论教学'
        }, {
          value: '实践教学',
          label: '实践教学'
        }
      ],
      progress:false,
      percentage:0,
      courserData:{
        name:'',
        englishName:'',
        courseCode:'',
        courseMold:'',
        orientCrowd:'',
        logo:'',
        applicableToMajor:'',
        profiles:'',
        totalClassHour:'',
        ownerType:'school_teacher',
        remarks:''
      },
      courserObjArr:[
        {value:'中职',label:'中职'},
        {value:'高职',label:'高职'},
        {value:'本科',label:'本科'},
        {value:'企业',label:'企业'},
      ],
      chapterWindowSHow:false,
      courseId:'',
      courseChapter:{
        name:'',
        knob:'',
      },
      coursejieData:'',
      courseChapterArr:[],
      addCourseJie:{},
      chapterChildren:[
      ],
      chapterJieShow:false,
      courseChapterwindow:false,
      amendChapterActive:false,
      amendJIeSHow:false,
      amendJieWindow:false,
      fatherId:'',
      uploadId:[],
      uploadName:'',
      uploadReaousId:'',
      teacherProgramme:[]
    };
  },
  methods: {
    //   下一步
    goNextStep() {
      if(this.courserData.name==''){
        this.$message("课程名称不可为空");
        return
      }
      this.addCourseOne = false;
      this.threeAddCourse=true;
      this.addCourseTwo=false;
      this.addCoursethree=true
      this.procedureActive=1;
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      let data={
        name:this.courserData.name,
        englishName:this.courserData.englishName,
        courseCode:this.courserData.courseCode,
        courseMold:this.courserData.courseMold,
        orientCrowd:this.courserData.orientCrowd,
        logo:this.courserData.logo,
        applicableToMajor:this.courserData.applicableToMajor,
        profiles:this.courserData.profiles,
        totalClassHour:this.courserData.totalClassHour,
        ownerType:'school_teacher',
        remarks:this.courserData.remarks,
        schoolId:userInfo.schoolId,
        ownerId:userInfo.id
      };

      if(this.courserData&&Object.keys(this.courserData).length&&this.$route.query.id){
        data['id']=this.$route.query.id
      };
      createAmendTeacherCourse(data).then(res=>{
        if(res.code===0&&!this.$route.query.id){
          this.$message({
            message: '创建课程成功',
            type: 'success'
          });
          this.courseId=res.data.id;
          let query={
            platformCourseId:res.data.id,
            teacherId:userInfo.id,
            thirdPartyStorageId:this.uploadId[0],
            resourceType:'2',
            syllabusData:'1',
            resourceId:this.uploadReaousId,
            resourceName:this.uploadName
          };
          addTeacherResource(query).then(res=>{
            if(res.code!==0){
              this.$message.error(res.msg)
            }
          })
        }else if(res.code===0&&this.$route.query.id){
          this.$message({
            message: '修改课程成功',
            type: 'success'
          });
          this.courseId=res.data.id;
          let query={
            platformCourseId:res.data.id,
            teacherId:userInfo.id,
            thirdPartyStorageId:this.uploadId[0],
            resourceType:'2',
            syllabusData:'1',
            resourceId:this.uploadReaousId,
            resourceName:this.uploadName
          }
          addTeacherResource(query).then(res=>{
            if(res.code!==0){
              this.$message.error(res.msg)
            }
          })
        }else{
          this.$message.error(res.msg);
        }
      })

    },
    goNextStepone(){
      this.addCourseOne = false;
      this.threeAddCourse=false;
      this.procedureActive=1;
      this.addCourseTwo=true;
      this.addCoursethree=false
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      let data = {
        platformCourseId: this.courserData.id,
        page: 1,
        size:20,
        teacherId:userInfo.id,
        syllabusData:'1'
      }
      //获取教学大纲
      selectAddTeacherResourceList(data).then(res=>{
        this.teacherProgramme=res.data.records
      })
    },
    // 3上一步
    lastStep(){
      this.addCourseOne = false;
      this.addCourseTwo = true;
      this.threeAddCourse=false;
      this.addCoursethree=false
      this.procedureActive=0;

    },
    //去第一步
    goOneStep(){
      this.addCourseOne = true;
      this.addCourseTwo = false;
      this.threeAddCourse=false;
      this.addCoursethree=false
      this.procedureActive=0;
    },
    // 下一步
    nextStepTwo(){
      this.addCourseOne = false;
      this.threeAddCourse=false;
      // this.fourAddCourse=true;
      this.addCourseTwo=false
      this.procedureActive=2
    },
    // 上一步
    lastBackStep(){
      this.addCourseOne = false;
      this.threeAddCourse=true;
      this.fourAddCourse=false;
      this.addCourseTwo=false
      this.procedureActive=1
    },
    // 下一步创建成功
    lastSuccecs(){
      this.$message({
        message: '创建完成',
        type: 'success'
      });
      this.$router.push({path:'/classCenter'});
      sessionStorage.setItem('creatCourseId',"8-1")
    },
    // 显示操作
    changeOperation() {
      this.operationShow = !this.operationShow;
    },
    // 新建章节弹窗显示
    ChnageSection(item) {
      this.constructionSection = true;
      this.chapterJieShow=true
      this.chapterWindowSHow=false;
      // if(item.children){
      //   this.chapterChildren=item.children;
      // }
      this.addCourseJie=item;
    },
    //显示章弹窗
    ChnageChapterSection(){
      this.courseChapterwindow = true;
      this.chapterWindowSHow=true;
      this.courseChapter.name='';
      this.amendChapterActive=false
    },
    //显示修改章弹窗
    amendCoursechapter(item){
      this.courseChapterwindow=true;
      this.amendChapterActive=true;
      this.addCourseJie=item;
    },
    //修改节
    amendCourseJIe(item,bigItem){
      this.fatherId=bigItem.id
      this.amendJIeSHow=true;
      this.amendJieWindow=true
      this.addCourseJie=item;

    },
    // 资源操作
    changeResource(){
        this.resourceShow = !this.resourceShow;
    },
    // 上传资源弹窗显示
    changeTargetWindow(){
      this.resourceUpLoadShow=true
    },
    //上传文件
    uploadFile(res){
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if(!res.file)return
      let fileType = filePlayType(res.file.name);
      if(fileType === 'avTypeAry'||fileType === 'audioTypeAry'){
        this.progress=true
        let aliYundata={
          "fullFileName": res.file.name,
          "coverUrl": "www.xn---xn--2d4a-439uw60e.com",
          "fileSize": res.file.size,
          "schoolId": userInfo.schoolId
        }
        let uploaderObj  = createUploader(aliYundata,"",(res,uploadInfo)=>{
          if(res === true){
            let userInfo = JSON.parse(localStorage.getItem('userInfo'))
            let data={
              thirdPartyStorageId:uploadInfo.uploadInfo.videoId,
              fileOriginalName:uploadInfo.uploadInfo.file.name,
              fileSize:uploadInfo.uploadInfo.file.size,
              teacherId:userInfo.id,
              schoolId:userInfo.schoolId
            };
            addTeacherResourceAliYun(data).then(res=>{
              if(res.code===0){
                this.$message({
                  message: "上传成功",
                  type: "success",
                  duration: 2000
                });
                let upId=res.data.thirdPartyStorageId;
                this.uploadName=res.data.fileOriginalName;
                this.uploadReaousId=res.data.id
                this.uploadId.push(upId);
              }else{
                this.$message.error(res.msg)
              }
            })
          }
        },(uploadInfo, totalSize, progress)=>{
          this.percentage=progress*100
        })
        uploaderObj.addFile(res.file)
        uploaderObj.startUpload()
      }else{
        var forms = new FormData()
        forms.append('file',res.file)
        forms.append('bizType','teacherResource')
        forms.append('aliVideoOssData',1);
        forms.append('schoolId',userInfo.schoolId)
        teacherUpLoad(forms).then(res=>{
          if(res.code==0){
            this.$message({
              message: "上传成功",
              type: "success",
              duration: 2000
            });
            let upId=res.data.thirdPartyStorageId;
            this.uploadName=res.data.fileOriginalName;
            this.uploadReaousId=res.data.id
            this.uploadId.push(upId);
          }else{
            this.$message.error(res.msg)
          }
        })
      }
    },
    //创建章
    createCourseCj(){
      this.courseChapterwindow=false;
      this.constructionSection = false;
      this.createCoursechapter()
    },
  //  创建章
    createCoursechapter(){
      if(!this.courseChapter&&!this.courseChapter.name){
        this.$message("课程章节名称不可为空");
        return
      }
      let data={
        platformCourseId:this.courseId,
        name:this.chapterWindowSHow && !this.amendChapterActive?this.courseChapter.name:this.addCourseJie.name,
        pid:0
      };
      if(this.chapterJieShow){
        data['pid']=this.addCourseJie.id;
        data['name']=this.coursejieData
      }
      if(this.amendJIeSHow){
        data['pid']=this.fatherId;
        data['id']=this.addCourseJie.id;
      }
      if(this.amendChapterActive&&!this.chapterJieShow){
        data['id']=this.addCourseJie.id
      }
      cresteTeacherCoursePreper(data).then(res=>{
        if(this.amendChapterActive || this.amendJIeSHow){
          if(res.code===0){
            this.$message({
              message: "修改章节成功",
              type: "success",
              duration: 2000
            });
            this.amendJIeSHow=false;
            this.amendJieWindow=false
          }
        }else if(this.chapterWindowSHow ||this.chapterJieShow){
          if(res.code===0){
            this.$message({
              message: "创建章节成功",
              type: "success",
              duration: 2000
            });
            if(res.data.pid==0){
              this.courseChapterArr.push(res.data);
              this.constructionSection = false;
              this.chapterJieShow=false;
            }else{
              this.courseChapterArr.forEach(item=>{
                if(item.id==res.data.pid){
                  if(item.children){
                    item.children.push(res.data)
                  }else{
                    item.children=[];
                    let children=[]
                    children.push(res.data);
                    item.children=children
                  }
                }
              })
              this.constructionSection = false;
              this.chapterJieShow=false;
            }
          }
        }else{
          this.$message.error(res.msg)
        }
      })
    },
  //  获取章节信息
    getChapterjieInfo(){
      let courseItemList = this.courserData.courseItemList
      const listToTree = (listArr, pid) => {
        let tree = [];
        if(listArr&&listArr.length){
          listArr.forEach((item, index) => {
            if (item.pid === pid) {
              let child = listToTree(courseItemList, item.id)
              let data = {...item}
              child && child.length && (data['children'] = child)
              tree.push(data)
            }
          })
        }
        return tree;
      }
      this.courseChapterArr = listToTree(courseItemList, '0')
    },
  //  删除课程章
    deleteCoursePreper(item){
      let data={
        id:item.id
      };
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let index = this.courseChapterArr.indexOf(item);
        if (index !== -1 && item.pid==0) {
          this.courseChapterArr.splice(index, 1);
        }else{
          this.courseChapterArr.forEach(childrenItem=>{
            let childrenIndex = childrenItem.children.indexOf(item);
            if(childrenIndex!==-1){
              childrenItem.children.splice(childrenIndex, 1);
            }
          })
        }
        deleteTeacherCoursePreper(data).then(res=>{
          if(res.code===0){
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }else{
            this.$message.error(res.msg);
          }
        })
      }).catch(() => {

      });
    },
    //  删除列表中的备课资源
    deleteResourceListIndex(item){
      this.$confirm("确认移出?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            let index = this.teacherProgramme.indexOf(item);
            if (index !== -1) {
              this.teacherProgramme.splice(index, 1);
            }
            let data={
              id:item.id,
            }
            deleteAddTeacherResourceList(data).then(res => {
              if (res.code == 0) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                  duration: 2000
                });
              }
            })
          })
          .catch(() => {});
    },
  },
  mounted() {
    document.documentElement.scrollTop = 0;
    let myCourseData=sessionStorage.getItem('myCourseData')
    if(myCourseData){
      this.courserData=JSON.parse(sessionStorage.getItem('myCourseData'))
    };
    this.getChapterjieInfo()
  }
};
</script>
<style scoped>
@import "../../public/css/addCourse.css";
</style>
